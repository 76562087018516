import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, getDoc, getDocs, doc, where, } from 'firebase/firestore'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';


function Printbill() {

    const printRef = useRef();
    const { documentId } = useParams();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const [loadincount, setloadincount] = useState(true);
    const [username, setusername] = useState("");

    const [userData, setUserData] = useState();

    const [orderitems, setorderitems] = useState([]);
    const [nofopax, setnofopax] = useState("");
    const [tablename, settablename] = useState("");
    const [orderdate, setorderdate] = useState("");
    const [billno, setbillno] = useState("");
    const [subtotal, setsubtotal] = useState(0);
    const [tax, settax] = useState(0);
    const [servicecharge, setservicecharge] = useState(0);
    const [tip, settip] = useState(0);
    const [totalamount, settotalamount] = useState(0);
    const [roundoff, setroundoff] = useState(0);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchUsersData()
        });
    });

    const fetchUsersData = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        fetchData1();
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setorderdate(formattedDate);

        const timer = setTimeout(() => {
            handlePrint();
        }, 4000); // 2000 milliseconds = 2 seconds

        // Cleanup the timeout if the component is unmounted
        return () => clearTimeout(timer);


    }, []);


    const fetchData1 = async () => {
        try {
            if (documentId) {
                const docRef = doc(db, 'orders', documentId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log(docSnap.data());
                    setnofopax(docSnap.data().nofopax);
                    if (docSnap.data().tableid) {
                        const docRef1 = doc(db, 'seatings', docSnap.data().tableid);
                        const docSnap1 = await getDoc(docRef1);
                        settablename(docSnap1.data().seatname);
                    }
                    setbillno(docSnap.data().billno);
                    setservicecharge(docSnap.data().servicecharge);
                    settip(docSnap.data().tip);
                } else {
                    console.log('No such document!');
                }

                const q = query(collection(db, "orderitems"), where("orderid", "==", documentId));
                const querySnapshot = await getDocs(q);
                const orderItems = [];
                let sum = 0;
                let tax = 0;
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const total = data.total ? data.total : 0;
                    const taxAmount = data.taxamount ? data.taxamount : 0;
                    sum += total;
                    tax += taxAmount;
                    orderItems.push({ id: doc.id, data });
                });

                setorderitems(orderItems);
                settax(parseFloat(tax));
                setsubtotal(parseFloat(sum));
                const totalAmount = parseFloat(sum) + parseFloat(tax) + parseFloat(servicecharge) + parseFloat(tip);
                const roundoff = parseFloat(totalAmount.toFixed() - totalAmount.toFixed(2));
                setroundoff(roundoff);
                settotalamount(totalAmount + roundoff);
            }

        } catch (error) {
            console.log('Error fetching order items:', error.message);
        }
    }

    return (
        <div>
            <div className="content-wrapper">
                <div style={{ padding: 20 }} ref={printRef}>
                    <div className='col-sm-4'>
                        <center>
                            <h4 style={{ textTransform: "uppercase" }}>THE SKYLAND HOTEL & RESTAURANT  </h4>
                            <span style={{ fontSize: 15, color: "black" }}>Near New Bus Stand, Latur Road, Tuljapur, Dist. Dharashiv, 413 601</span><br />
                            <span style={{ fontSize: 15, color: "black" }}>Mobile No: 7077779163, 7077779164, 7077779165 </span><br />
                            <span style={{ fontSize: 15, color: "black" }}>GSTIN No: 27AAWFP8677Q1ZN </span><br />
                        </center>
                        <br />
                        <table width="100%">
                            <tr>
                                <td style={{ color: "black", fontSize: 15 }}>Bill No : {billno} </td>
                                <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Table No : {tablename}</td>
                            </tr>
                            <tr >
                                <td style={{ color: "black", fontSize: 15 }}>Date :  {moment(orderdate).format('DD/MM/YYYY')} </td>
                                <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Time :  {moment().format('HH:mm:ss A')} </td>
                            </tr>
                            <tr >
                                <td colSpan={2} style={{ color: "black", fontSize: 15 }}>No of Person :  {nofopax} </td>
                            </tr>
                            <tr >
                                {/* <td style={{ color: "black", fontSize: 15 }}>Guest Name  :  {customer.name} </td>
                                <td style={{ textAlign: "right", color: "black", fontSize: 15 }}>Mobile  :  {customer.mobile} </td> */}
                            </tr>
                        </table>
                        <table className='billtables' width="100%" style={{ marginTop: 2 }}>
                            <tr>
                                <th>Item Name</th>
                                <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Qty</th>
                                <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Rate</th>
                                <th style={{ fontSize: 15, color: "black", textAlign: "right" }}>Total</th>
                            </tr>
                            {
                                orderitems.map((item, index) =>
                                    <tr key={index}>
                                        <td>{item.data.itemname}  </td>
                                        <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{item.data.quantity}</td>
                                        <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{item.data.rate}</td>
                                        <td style={{ fontSize: 15, color: "black", textAlign: "right" }}>{parseFloat(item.data.total).toFixed(2)}</td>
                                    </tr>
                                )
                            }
                        </table>
                        <h5 style={{ fontSize: 15, marginBottom: 10, marginTop: 5 }}>Sub Total   <span style={{ float: "right" }}> {subtotal}</span> </h5>
                        <h5 style={{ fontSize: 15, marginBottom: 10 }}>Cgst (2.5%)   <span style={{ float: "right" }}> {parseFloat(tax / 2)}</span> </h5>
                        <h5 style={{ fontSize: 15, marginBottom: 10 }}>Sgst (2.5%)   <span style={{ float: "right" }}> {parseFloat(tax / 2)}</span> </h5>
                        <h5 style={{ fontSize: 15, marginBottom: 10 }}>Round Off    <span style={{ float: "right" }}> {parseFloat(roundoff)}</span> </h5>
                        {/* <h5 style={{ fontSize: 15,marginBottom: 10 }}>Service Charge + Tip    <span style={{ float: "right" }}> {parseFloat(servicecharge) + parseFloat(tip)}</span> </h5> */}
                        <h5 style={{ fontSize: 15, marginBottom: 10 }}>Total Amount   <span style={{ float: "right" }}> {totalamount}</span> </h5>
                        <hr />
                        <center>
                            <h6>Thank You</h6>
                        </center>
                        <span style={{ fontSize: 14, color: "black" }}>Prepared By  : {username}</span><br />
                    </div>
                </div>
            </div >

        </div >
    )
}



export default Printbill
