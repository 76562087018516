import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, getDocs, } from 'firebase/firestore'
import moment from 'moment';
import Select from 'react-select'
import { useReactToPrint } from 'react-to-print';


export default function SalePurchaseReport() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setfromdate(formattedDate);
        settodate(formattedDate);
    }, []);

    const printRef = useRef();
    const [reportData, setreportData] = useState([]);
    const [type, settype] = useState("Sale");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [custtype, setcusttype] = useState("");
    const [totalamount, settotalamount] = useState("");
    const [paidamount, setpaidamount] = useState("");
    const [discount, setdiscount] = useState("");
    const [remaining, setremaining] = useState("");
    const [paymodes, setpaymodes] = useState([]);

    const [total, settotal] = useState(0);


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const getReport = async () => {
        if (type === "") {
            Toast.fire({ icon: "error", title: 'Please select report type' });
            return;
        }

        settotalamount(0);
        setpaidamount(0);
        setdiscount(0);
        setremaining(0);

        try {
            // Step 1: Fetch all necessary data in parallel
            const qPayModes = query(collection(db, 'restopaymodes'));
            const qOrders = query(
                collection(db, 'orders'),
                where('orderdate', '>=', fromdate),
                where('orderdate', '<=', todate),
                where('totalamount', '>', 0)
            );

            const [paymodesSnapshot, ordersSnapshot] = await Promise.all([
                getDocs(qPayModes),
                getDocs(qOrders)
            ]);

            const allOrders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Step 2: Calculate totals by paymode
            const combinedData = paymodesSnapshot.docs.map(paymodeDoc => {
                const paymode = paymodeDoc.data().paymode;
                const paymodeOrders = allOrders.filter(order => order.paytype === paymode);

                const totalPaymodeSum = paymodeOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);

                return {
                    id: paymodeDoc.id,
                    paymode: paymode,
                    ordersData: paymodeOrders, // Store related orders
                    totalamount: totalPaymodeSum.toFixed(2),
                };
            });

            const total = combinedData.reduce((sum, pm) => sum + parseFloat(pm.totalamount), 0);

            // Step 3: Set the total and paymode data
            settotal(total.toFixed(2));
            setpaymodes(combinedData);
            const q1 = query(collection(db, 'orders'), orderBy("created", "desc"), where('orderdate', '>=', fromdate),
                where('orderdate', '<=', todate), where('billtype', '==', "Room Posting"), where('status', '==', "Bill Pending"));
            onSnapshot(q1, async (querySnapshot1) => {
                const promises = querySnapshot1.docs.map(async (doc1) => {
                    let rooms = [];
                    let custname = "";
                    let mobile = "";
                    let staffname = "";
                    const orderData = doc1.data();
                    const customerPromise = orderData.bookingId
                        ? getDoc(doc(db, "bookings", orderData.bookingId))
                        : Promise.resolve(null);
                    const [customerSnap] = await Promise.all([customerPromise]);

                    custname = customerSnap.data()?.customers.custname || "";
                    mobile = customerSnap.data()?.customers.mobile || "";
                    rooms = customerSnap.data()?.rooms || [];
                    staffname = orderData.staff?.label || "";
                    return {
                        id: doc1.id,
                        data: orderData,
                        custname: custname,
                        staffname: staffname,
                        mobile: mobile,
                        rooms: rooms,
                    };
                });
                const finalData = await Promise.all(promises);
                setreportData(finalData);
            });

        } catch (error) {
            Toast.fire({ icon: "error", title: error.message });
            console.error(error.message);
        }
    };



    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Sale Purchase Report  ||</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <label>Report Type  </label>
                                        <select className='form-control' style={{ height: 35, fontSize: 14 }} value={type} onChange={(e) => settype(e.target.value)}>
                                            <option value="Sale">Sale</option>
                                            {/* <option value="Purchase">Purchase</option> */}
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <label>From Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>To Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <br />
                                        <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" ref={printRef}>
                                {/* <center>
                                    <h6>MATOSHRI BAR</h6>
                                    <span>Dharashiv Road, Tuljapur, 413501</span><br />
                                    <span>Mobile No: 9921060146</span>
                                </center><hr /> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <span><b>From Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <h6 style={{ textTransform: "uppercase" }}>{type} Report</h6>
                                    </div>
                                    <div className='col-sm-4'>
                                        <span style={{ float: "right" }}><b>To Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                </div>
                                <div className='row '>
                                    <table className='customers3'>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Date</th>
                                            <th>Bill No</th>
                                            <th>Bill Type</th>
                                            <th style={{ textAlign: "right" }}>Total Amount</th>
                                            <th style={{ textAlign: "right" }}>Paid Amount</th>
                                            <th style={{ textAlign: "right" }}>Discount</th>
                                            <th style={{ textAlign: "right" }}>Reamaining</th>
                                            <th style={{ textAlign: "right" }}>Status</th>
                                            <th style={{ textAlign: "right" }}>Pay Type</th>
                                        </tr>
                                        {
                                            paymodes.map((item, index) =>
                                                <>
                                                    <tr key={index}>
                                                        <td colSpan={10}><b>{item.paymode}</b></td>
                                                    </tr>
                                                    {
                                                        item.ordersData.map((item1, index) =>
                                                            <tr key={index}>
                                                                <td width="80">{index + 1}</td>
                                                                <td>{moment(item1.orderdate).format('DD-MM-YYYY')}</td>
                                                                {/* <td>{item1.dealername}</td> */}
                                                                <td>{item1.billno}</td>
                                                                <td>{item1.billtype}</td>
                                                                <td style={{ textAlign: "right" }}>{parseFloat(item1.totalamount).toFixed(2)}</td>
                                                                <td style={{ textAlign: "right" }}>{parseFloat(item1.paid).toFixed(2)}</td>
                                                                <td style={{ textAlign: "right" }}>{parseFloat(item1.discount).toFixed(2)}</td>
                                                                <td style={{ textAlign: "right" }}>{parseFloat(item1.remaining).toFixed(2)}</td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {
                                                                        item1.status === 'Cancled' &&
                                                                        <b style={{ color: "red" }}>Cancled</b>
                                                                    }
                                                                </td>
                                                                <td style={{ textAlign: "right" }}>{item1.paytype}</td>
                                                            </tr>

                                                        )
                                                    }

                                                </>
                                            )
                                        }


                                    </table>
                                    <table className="customers3">
                                        <thead>
                                            <tr>
                                                <th colSpan={12}>ROOM POSTING</th>
                                            </tr>
                                            <tr>
                                                <td><b>Sr No</b></td>
                                                <td><b>Order Date</b></td>
                                                <td><b>Bill No</b></td>
                                                <td><b>Customer Name</b></td>
                                                <td><b>Room No</b></td>
                                                <td style={{ textAlign: "right" }}><b>Grand Total </b></td>
                                                <td style={{ textAlign: "right" }}><b>Discount </b></td>
                                                <td style={{ textAlign: "right" }}><b>Paid Amount</b></td>
                                                <td style={{ textAlign: "right" }}><b>Remaining</b></td>
                                                <td><b>Bill Type</b></td>
                                                <td><b>Pay Type</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reportData.length === 0 &&
                                                <tr>
                                                    <td className='text-center' colSpan={14}>No Data Found...</td>
                                                </tr>
                                            }
                                            {
                                                reportData.map((item, num = 1) =>
                                                    <tr key={num + 1}>
                                                        <td width="80">{num + 1}</td>
                                                        <td>{moment(item.data.orderdate).format('DD-MM-YYYY')}</td>
                                                        <td>{item.data.billno}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{item.custname}</td>
                                                        <td>
                                                            <div className='row'>
                                                                {item.rooms.map((item1, index) =>
                                                                    <span key={index}>{item1.roomname} &nbsp;  </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.totalamount).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.discount).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.paid).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.totalamount - item.data.paid - item.data.discount).toFixed(2)}</td>
                                                        <td>{item.data.billtype}</td>
                                                        <td>{item.data.paytype}</td>

                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>

                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <table className='customers' style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>PAYMODE</th>
                                                    <th>AMOUNT</th>
                                                </tr>
                                                {
                                                    paymodes.map((item, index) =>
                                                        <tr>
                                                            <th key={index}>{item.paymode}</th>
                                                            <td key={index}>{item.totalamount}</td>
                                                        </tr>
                                                    )}
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <th>{total}</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <center>
                                <button onClick={handlePrint} className='btn btn-info'>Print Report</button>
                            </center><br />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
