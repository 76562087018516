import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBAu3IJDsJk4cncnXsNftSzQMkTQrSgeLg",
  authDomain: "demoskyland.firebaseapp.com",
  projectId: "demoskyland",
  storageBucket: "demoskyland.appspot.com",
  messagingSenderId: "54476227582",
  appId: "1:54476227582:web:6eac2822128954444036af",
  measurementId: "G-RHJNH60P0H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore(app);


export { db, auth };

export default app;