import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { collection, getCountFromServer, getDocs, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import "../App.css";
import Menu from '../panel/Menu';
import moment from 'moment';

function Dashboard() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [paymodes, setpaymodes] = useState([]);
  const [userData, setUserData] = useState();
  const [bookedorders, setbookedorders] = useState(0);
  const [availableorders, setavailableorders] = useState(0);
  const [creditorders, setcreditorders] = useState(0);
  const [allorders, setallorders] = useState(0);

  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");

  const [allsum, setallsum] = useState(0);
  const [postingSUm, setpostingSUm] = useState(0);
  const [creditSum, setcreditSum] = useState(0);
  const [liveSUm, setliveSUm] = useState(0);


  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
      fetchData1()
    });
  }, [userData]);


  const fetchData1 = async () => {
    try {

      const today = new Date();
      // Set time to the start of the day (00:00:00)
      const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

      // Step 1: All orders for today
      const q1 = query(collection(db, 'orders'), where('totalamount', '>', 0), where('created', '>=', start), where('created', '<=', end));
      const snapshot1 = await getDocs(q1);
      setallorders(snapshot1.size);
      onSnapshot(q1, (querySnapshot) => {
        let totalSum = 0;
        querySnapshot.forEach((doc) => {
          const orderData = doc.data();
          totalSum += orderData.totalamount || 0;  // Add totalamount to the sum
        });
        setallsum(totalSum.toFixed(2));
      });

      // Step 2: Booked orders for today
      const q2 = query(collection(db, 'orders'), where('totalamount', '>', 0), where('status', '==', "Booked"), where('created', '>=', start), where('created', '<=', end));
      const querySnapshot1 = await getDocs(q2);
      setbookedorders(querySnapshot1.size);
      onSnapshot(q2, (querySnapshot) => {
        let totalSum = 0;
        querySnapshot.forEach((doc) => {
          const orderData = doc.data();
          totalSum += orderData.totalamount || 0;  // Add totalamount to the sum
        });
        setliveSUm(totalSum.toFixed(2));
      });

      // Step 3: Room Posting orders for today
      const q3 = query(collection(db, 'orders'), where('totalamount', '>', 0), where("billtype", '==', 'Room Posting'), where('created', '>=', start), where('created', '<=', end));
      const snapshot2 = await getDocs(q3);
      setavailableorders(snapshot2.size);
      onSnapshot(q3, (querySnapshot) => {
        let totalSum = 0;
        querySnapshot.forEach((doc) => {
          const orderData = doc.data();
          totalSum += orderData.totalamount || 0;  // Add totalamount to the sum
        });
        setpostingSUm(totalSum.toFixed(2));
      });

      // Set date range for today's orders
      let fromdate = moment().format('YYYY-MM-DD');
      let todate = moment().format('YYYY-MM-DD');

      // Query for orders with 'Credit Account' billtype
      const q4 = query(
        collection(db, 'orders'),
        where('totalamount', '>', 0),
        where("billtype", '==', 'Credit Account'),
        where('created', '>=', start),
        where('created', '<=', end)
      );

      // Fetch 'Credit Account' orders
      const snapshot3 = await getDocs(q4);
      setcreditorders(snapshot3.size);

      // Calculate total sum for 'Credit Account' orders
      let totalCreditSum = snapshot3.docs.reduce((sum, doc) => sum + (doc.data().totalamount || 0), 0);
      setcreditSum(totalCreditSum.toFixed(2));

      // Fetch all orders for the current date and payment modes
      const ordersQuery = query(
        collection(db, 'orders'),
        where('orderdate', '>=', fromdate),
        where('orderdate', '<=', todate),
        where('totalamount', '>', 0)
      );
      const ordersSnapshot = await getDocs(ordersQuery);
      const allOrders = ordersSnapshot.docs.map(doc => doc.data());

      // Fetch payment modes
      const paymodesSnapshot = await getDocs(query(collection(db, 'restopaymodes'), orderBy('createdAt', 'asc')));
      const paymodesData = paymodesSnapshot.docs.map(doc => doc.data());

      // Group and sum totalamount by payment mode
      const combinedData = paymodesData.map(paymodeDoc => {
        const paymodeOrders = allOrders.filter(order => order.paytype === paymodeDoc.paymode);
        const totalPaymodeSum = paymodeOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);

        return {
          id: paymodeDoc.id,
          paymode: paymodeDoc.paymode,
          totalamount: totalPaymodeSum.toFixed(2),
        };
      });

      // Set the final data
      setpaymodes(combinedData);



    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
      console.log(error.message);

    }
  }

  const getReport = async () => {
    if (fromdate === "" || todate === "") {
      Toast.fire({ icon: "error", title: 'Please Select From Date To Date' });
      return;
    }

    try {
      // Function to convert YYYY-MM-DD to Firebase Timestamp
      const convertToTimestamp = (dateString) => {
        const date = new Date(dateString + 'T00:00:00'); // Convert to Date object at midnight
        return Timestamp.fromDate(date);  // Convert to Firebase Timestamp
      };

      // Convert fromdate and todate to Firebase Timestamps
      const start = convertToTimestamp(fromdate); // Start of the selected date
      const end = convertToTimestamp(todate);     // End of the selected date

      // Fetch all orders once with the given time range
      const qOrders = query(
        collection(db, 'orders'),
        where('totalamount', '>', 0),
        where('created', '>=', start), // Use Firebase Timestamp for created field
        where('created', '<=', end)
      );

      // Fetch payment modes
      const qPayModes = query(collection(db, 'restopaymodes'), orderBy('createdAt', 'asc'));

      // Execute both queries in parallel
      const [ordersSnapshot, paymodesSnapshot] = await Promise.all([
        getDocs(qOrders),
        getDocs(qPayModes)
      ]);

      // Process orders
      const allOrders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Set total number of orders and their sum
      const totalOrders = allOrders.length;
      const totalSum = allOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);
      setallorders(totalOrders);
      setallsum(totalSum.toFixed(2));

      // Filter orders by status/type and calculate their sums
      const bookedOrders = allOrders.filter(order => order.status === 'Booked');
      const bookedSum = bookedOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);
      setbookedorders(bookedOrders.length);
      setliveSUm(bookedSum.toFixed(2));

      const roomPostingOrders = allOrders.filter(order => order.billtype === 'Room Posting');
      const roomPostingSum = roomPostingOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);
      setavailableorders(roomPostingOrders.length);
      setpostingSUm(roomPostingSum.toFixed(2));

      const creditAccountOrders = allOrders.filter(order => order.billtype === 'Credit Account');
      const creditAccountSum = creditAccountOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);
      setcreditorders(creditAccountOrders.length);
      setcreditSum(creditAccountSum.toFixed(2));

      // Process payment modes
      const paymodesData = paymodesSnapshot.docs.map(doc => doc.data());
      const combinedData = paymodesData.map(paymodeDoc => {
        const paymodeOrders = allOrders.filter(order => order.paytype === paymodeDoc.paymode);
        const totalPaymodeSum = paymodeOrders.reduce((sum, order) => sum + (order.totalamount || 0), 0);

        return {
          id: paymodeDoc.id,
          paymode: paymodeDoc.paymode,
          totalamount: totalPaymodeSum.toFixed(2),
        };
      });

      // Set the final payment mode data
      setpaymodes(combinedData);

    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
      console.log(error.message);
    }
  }


  return (
    <div>
      <Menu />
      <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
        <section className="content"><br />
          <div className="container-fluid">
            <button className="btn btn-secondary " >|| Dashboard ||</button><br /><br />
            {/* <div className="card card-primary card-outline">
              <div className="card-body table-responsive p-3" >
                <div className='row '>
                  <div className="col-sm-3">
                    <label>From Date </label>
                    <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                  </div>
                  <div className="col-sm-3">
                    <label>To Date </label>
                    <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                  </div>
                  <div className='col-sm-3'>
                    <br />
                    <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-pink">
                                    <div className="inner">
                                        <h3>{todayCount + todayCount1}</h3>
                                        <p>Today's  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/todays" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-orange">
                                    <div className="inner">
                                        <h3>{tommCount + tommCount1}</h3>
                                        <p>Tommorrow's  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/tommorrows" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-purple">
                                    <div className="inner">
                                        <h3>{thisMonth + thisMonth1}</h3>
                                        <p>This Month  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/thismonth" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-blue">
                                    <div className="inner">
                                        <h3>{nextMonth + nextMonth1}</h3>
                                        <p>Next Month   Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/nextmonth" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>

                        </div> */}
            <div className="row">
              <div className="col-lg-3 col-6">

                <div className="small-box bg-pink">
                  <div className="inner">
                    <h3>{allorders}</h3>
                    <p>All Orders</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">

                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{bookedorders}</h3>
                    <p>Live Orders</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6">

                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{availableorders}</h3>
                    <p>Room Posting</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6">

                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{creditorders}</h3>
                    <p>Credit Orders</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add"></i>
                  </div>
                </div>
              </div>

            </div>
            <div className='row'>
              {
                paymodes.map((item, index) =>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-teal">
                      <div className="inner">
                        <h3>{item.totalamount}</h3>
                        <p> {item.paymode}</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add"></i>
                      </div>
                    </div>
                  </div>

                )
              }


            </div>
          </div>
        </section>

      </div>


    </div>
  )
}

export default Dashboard
