import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, updateDoc, } from 'firebase/firestore'
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';


export default function Roompostingreport() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    useEffect(() => {
        fetchData1()
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setfromdate(formattedDate);
        settodate(formattedDate);
    }, []);

    const printRef = useRef();
    const [reportData, setreportData] = useState([]);
    const [type, settype] = useState("All");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [orderId, setorderId] = useState("");
    const [paytype, setpaytype] = useState("");
    const [paymodes, setpaymodes] = useState([]);

    const fetchData1 = () => {
        const q66 = query(collection(db, 'restopaymodes'))
        onSnapshot(q66, (querySnapshot) => {
            setpaymodes(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
    }


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const getReport = () => {
        if (type === "") {
            Toast.fire({ icon: "error", title: 'Please select report type' });
            return;
        }

        setreportData([]);
        const q1 = query(collection(db, 'orders'), orderBy("created", "desc"), where('orderdate', '>=', fromdate),
            where('orderdate', '<=', todate), where('billtype', '==', "Room Posting"), where('status', '==', "Bill Pending"));
        onSnapshot(q1, async (querySnapshot1) => {
            const promises = querySnapshot1.docs.map(async (doc1) => {
                let rooms = [];
                let custname = "";
                let mobile = "";
                let staffname = "";
                const orderData = doc1.data();
                const customerPromise = orderData.bookingId
                    ? getDoc(doc(db, "bookings", orderData.bookingId))
                    : Promise.resolve(null);
                const [customerSnap] = await Promise.all([customerPromise]);

                custname = customerSnap.data()?.customers.custname || "";
                mobile = customerSnap.data()?.customers.mobile || "";
                rooms = customerSnap.data()?.rooms || [];
                staffname = orderData.staff?.label || "";
                return {
                    id: doc1.id,
                    data: orderData,
                    custname: custname,
                    staffname: staffname,
                    mobile: mobile,
                    rooms: rooms,
                };
            });
            const finalData = await Promise.all(promises);
            setreportData(finalData);
        });

    }

    const updateData = async () => {
        await updateDoc(doc(db, 'orders', orderId), {
            paytype: paytype,
            status: "Active"
        });
        getReport();
        Toast.fire({ icon: "success", title: 'Room Posting Payment Settlement Successfully Done' });
    }


    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Room Posting Report  ||</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <label>Posting Type  </label>
                                        <select className='form-control' style={{ height: 35, fontSize: 14 }} value={type} onChange={(e) => settype(e.target.value)}>
                                            <option></option>
                                            <option value="All">All</option>
                                            {/* <option value="Paid">Paid</option> */}
                                            {/* <option value="Not Paid">Not Paid</option> */}
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <label>From Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>To Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <br />
                                        <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" ref={printRef}>
                                {/* <center>
                                    <h6>MATOSHRI BAR</h6>
                                    <span>Dharashiv Road, Tuljapur, 413501</span><br />
                                    <span>Mobile No: 9921060146</span>
                                </center><hr /> */}
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <span><b>From Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <h6 style={{ textTransform: "uppercase" }}>{type} Report</h6>
                                    </div>
                                    <div className='col-sm-4'>
                                        <span style={{ float: "right" }}><b>To Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                </div>
                                <div className='row '>
                                    <table className="table table-bordered table-hover table-striped table-head-fixed">
                                        <thead>
                                            <tr>
                                                <th>Sr No</th>
                                                <th>Order Date</th>
                                                <th>Bill No</th>
                                                <th>Customer Name</th>
                                                <th>Room No</th>
                                                <th style={{ textAlign: "right" }}>Grand Total </th>
                                                <th style={{ textAlign: "right" }}>Discount </th>
                                                <th style={{ textAlign: "right" }}>Paid Amount</th>
                                                <th style={{ textAlign: "right" }}>Remaining</th>
                                                <th>Staff</th>
                                                <th>Bill Type</th>
                                                <th>Pay Type</th>
                                                <th>Note</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reportData.length === 0 &&
                                                <tr>
                                                    <td className='text-center' colSpan={14}>No Data Found...</td>
                                                </tr>
                                            }
                                            {
                                                reportData.map((item, num = 1) =>
                                                    <tr key={num + 1}>
                                                        <td width="80">{num + 1}</td>
                                                        <td>{moment(item.data.orderdate).format('DD-MM-YYYY')}</td>
                                                        <td>{item.data.billno}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{item.custname}</td>
                                                        <td>
                                                            <div className='row'>
                                                                {item.rooms.map((item1, index) =>
                                                                    <span key={index}>{item1.roomname} &nbsp;  </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.totalamount).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.discount).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.paid).toFixed(2)}</td>
                                                        <td style={{ textAlign: "right" }}>{parseFloat(item.data.totalamount - item.data.paid - item.data.discount).toFixed(2)}</td>
                                                        <td>{item.staffname}</td>
                                                        <td>{item.data.billtype}</td>
                                                        <td>{item.data.paytype}</td>
                                                        <td>{item.data.note}</td>
                                                        <td>
                                                            <a onClick={() => setorderId(item.id)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-check"></i> Done</a>&nbsp;
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <center>
                                <button onClick={handlePrint} className='btn btn-info'>Print Report</button>
                            </center><br />
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Settlement Done</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <label>Payment Type</label>
                                    <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                        <option></option>
                                        {
                                            paymodes.map((item, index) =>
                                                <option key={index} value={item.data.paymode}>{item.data.paymode}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-info" onClick={updateData} >Update</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
