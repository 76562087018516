import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// Panel Code ===================================================================
import Login from './auth/Login';
import Register from './auth/Register';
import Header from './panel/Header';
import Menu from './panel/Menu';
import Footer from './panel/Footer';
import Adminmenu from './panel/Adminmenu';


//  Customer Side ============================================================ 
import Dashboard from './components/Dashboard';
import ProductDetails from './components/ProductDetails';
import ProductCategory from './components/ProductCategory';
import ProductUnit from './components/ProductUnit';
import Customers from './components/Customers';
import CustomerAccount from './components/CustomerAccount';
import TableOrders from './components/TableOrders';
import DealerDetails from './components/DealerDetails';
import DealerAccount from './components/DealerAccount';
import AddPurchase from './components/AddPurchase';
import ViewPurchase from './components/ViewPurchase';
import CustomerOutstanding from './components/CustomerOutstanding';
import ExciseReport from './components/ExciseReport';
import SeatingCategory from './components/SeatingCategory';
import SeatingDetails from './components/SeatingDetails';
import SalePurchaseReport from './components/SalePurchaseReport';
import ViewOrders from './components/Vieworders';
import Staffdetails from './components/Staffdetails';
import Calendar from './components/Calendar';
import CustomerwiseReport from './components/CustomerwiseReport';
import Purchaseproduct from './components/Purchaseproduct';
import Propertydetails from './components/Propertydetails';
import Cheforders from './components/Cheforders';
import Propusers from './components/Propusers';
import Foodorders from './components/Foodorders';
import Roomservices from './components/Roomservice';

//  Admin Side ============================================================ 
import Admindashboard from './components/superadmin/Admindashboard';
import Addresto from './components/superadmin/Addresto';
import Restousers from './components/superadmin/Restousers';
import Itemnotes from './components/Itemnotes';
import Paymentmode from './components/Paymentmode';
import Roompostingreport from './components/Roompostingreport';
import Printbill from './components/Printbill';
import Customorder from './components/Customorder';



import { auth } from './firebase';

let role = localStorage.getItem('role');
function App() {

  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      //  
    });
  });

  return (
    <div className="wrapper">
      {
        !user ?
          <Router>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/Register' element={<Register />} />
            </Routes>
          </Router>
          :
          <>
            {
              role === 'SUPERADMIN' ?
                <>
                  <Adminmenu />
                  <Header />
                  <Router>
                    <Routes>
                      <Route path='/' element={<Admindashboard />} />
                      <Route path='/home' element={<Admindashboard />} />
                      <Route path='/add-restaurents' element={<Addresto />} />
                      <Route path='/restaurents-users' element={<Restousers />} />
                    </Routes>
                  </Router>
                  <Footer />
                </>
                :
                <>
                  <Menu />
                  <Header />
                  <Router>
                    <Routes>
                      <Route path='/' element={<Dashboard />} />
                      <Route path='/home' element={<Dashboard />} />
                      <Route path='/product-details' element={<ProductDetails />} />
                      <Route path='/purchase-products' element={<Purchaseproduct />} />
                      <Route path='/product-category' element={<ProductCategory />} />
                      <Route path='/product-unit' element={<ProductUnit />} />
                      <Route path='/seating-category' element={<SeatingCategory />} />
                      <Route path='/seating-details' element={<SeatingDetails />} />
                      <Route path='/credit-accounts' element={<Customers />} />
                      <Route path='/customer-account' element={<CustomerAccount />} />
                      <Route path='/table-orders' element={<TableOrders />} />
                      <Route path='/dealer-details' element={<DealerDetails />} />
                      <Route path='/dealer-account' element={<DealerAccount />} />
                      <Route path='/add-purchase' element={<AddPurchase />} />
                      <Route path='/view-purchase' element={<ViewPurchase />} />
                      <Route path='/customer-outstanding' element={<CustomerOutstanding />} />
                      <Route path='/excise-report' element={<ExciseReport />} />
                      <Route path='/sale-purchase-report' element={<SalePurchaseReport />} />
                      <Route path='/view-orders' element={<ViewOrders />} />
                      <Route path='/staff-details' element={<Staffdetails />} />
                      <Route path='/staff-attendence/:id' element={<Calendar />} />
                      <Route path='/customerwise-report' element={<CustomerwiseReport />} />
                      <Route path='/property-details' element={<Propertydetails />} />
                      <Route path='/property-users' element={<Propusers />} />
                      <Route path='/food-orders' element={<Foodorders />} />
                      <Route path='/item-canclation-note' element={<Itemnotes />} />
                      <Route path='/room-service' element={<Roomservices />} />

                      <Route path='/payment-modes' element={<Paymentmode />} />
                      <Route path='/room-posting-report' element={<Roompostingreport />} />
                      <Route path='/print-finalbill/:documentId' element={<Printbill />} />
                      <Route path='/custom-orders' element={<Customorder />} />
                    </Routes>
                  </Router>
                  <Footer />
                </>
            }

          </>
      }

    </div>
  );
}

export default App;
